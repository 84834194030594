import Vue from 'vue';
import * as types from './types';

export const state = {
    usuario: JSON.parse(localStorage.getItem('usuario')) || {},
    token: localStorage.getItem('token') || '',
    tokenExpiresOn: localStorage.getItem('token_expires_on'),
    isLoggedIn: '',
    isValidResetPassword: '',
    usernameAvailable: '',
    emailAvailable: true,
    usernameExistsMessage: '',
    usernameExists: '',
    email: '',
    validationCode: '',
    isEmailConfirmed: '',
    mediaTemp: {},
    images: {
        avatar: '',
        imagemDestaque: '',
    },
    filterConfigurations: {},
    deviceUuid: "",
    isTemporaryPassword: false,
    showDebug: false,
    createUserInviteCode: "",
    exibeExpiracao: false
};

export const mutations = {
    [types.SET_USER](state, data) {
        state.usuario = data;
    },
    [types.REGISTER_LOGIN](state, data) {
        // recebe expires em segundos e precisa converter em milisegundos
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('token_expires_on', data.token_expires_on);
        localStorage.setItem('usuario', JSON.stringify(data.usuario));
        state.token = data.token;
        state.tokenExpiresOn = data.token_expires_on;
        state.usuario = data.usuario;
        state.isLoggedIn = true;
    },
    [types.LOGOUT](state) {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expires_on');
        localStorage.removeItem('usuario');
        state.usuario = '';
        state.token = '';
        state.tokenExpiresOn = '';
        state.isLoggedIn = false;
        state.exibeExpiracao = false;
    },
    [types.CREATE_USER]() {
    },
    [types.RESET_PASSWORD]() {
    },
    [types.UPDATE_USER](state, data) {
        if (data.username === state.usuario.username) {
            state.usuario = data;
        }
    },
    [types.USERNAME_AVAILABLE](state, data) {
        state.usernameAvailable = data.username;
        state.usernameJaExiste = '';
        if (typeof data.username_exists !== 'undefined') {
            state.usernameExistsMessage = data.exist_message;
            state.usernameExists = data.username_exists;
        } else {
            state.usernameExistsMessage = "";
            state.usernameExists = "";
        }
    },
    [types.EMAIL_AVAILABLE](state, data) {
        state.emailAvailable = (data.status === "true");
    },
    [types.VALID_RESET_PASSWORD](state, data) {
        state.isValidResetPassword = data.status;
        state.validationCode = data.validationCode;
        state.email = data.email;
    },
    [types.EMAIL_CONFIRMED](state, data) {
        state.isEmailConfirmed = data;
    },
    [types.UPDATE_MEDIA_TEMP](state, data) {
        state.mediaTemp = data;
    },
    [types.SET_IMAGES](state, data) {
        state.images = data;
    },
    [types.UPDATE_FILTER_CONFIGURATIONS](state, data) {
        if (typeof data.section !== "undefined") {
            Vue.set(state.filterConfigurations, data.section, data.filter_configurations[data.section]);
        } else {
            state.filterConfigurations = data.filter_configurations;
        }
    },
    [types.CLEAR_FILTER_CONFIGURATIONS](state, data) {
        state.filterConfigurations = data;
    },
    [types.SET_DEVICE_UUID](state, data) {
        state.deviceUuid = data;
    },
    [types.SET_IS_TEMPORARY_PASSWORD](state, data) {
        state.isTemporaryPassword = data;
    },
    [types.SET_SHOW_DEBUG](state, data) {
        state.showDebug = data;
    },
    [types.SET_CREATE_USER_INVITE_CODE](state, data) {
        state.createUserInviteCode = data;
    },
    [types.SET_EXIBE_EXPIRACAO](state, data) {
        state.exibeExpiracao = data;
    },
};
