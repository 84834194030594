<template>
    <v-app :class="`${isDeviceIOS ? 'iosHeight' : ''} ma-0 pa-0`">
        <v-main class="layout ma-0 pa-0" :style="{ backgroundColor: $vuetify.theme.themes.light.background }">
            <LayoutHeader :active="active_header" style="position: absolute; left: 0; width: 100%; top: 0;" />
            <router-view
                :class="!isDeviceIOS ? 'scrollTouch' : ''"
                :style="contentSizeStyle"
                class="ma-0 pa-0"
                id="content"
            />
            <div id="profile-switch-container"></div>

            <div class="expiracao" v-if="exibeExpiracao">
                <div class="expiracao-interna1"><p>Sua sessão vai expirar em {{ remainingSeconds }}s.</p></div> 
                <div class="expiracao-interna2" @click="refreshSession"><p>Clique para atualizar.</p></div>
            </div>

            <FlashMessage />

            <component
                id="footer"
                :is="footerSideBar"
                :active="active_sidebar_footer"
                :class="isDeviceIOS ? 'iosFooter' : ''"
                :style="styleFooterSidebar"
              />
            <!-- Todox: testar num ios menu e sidebar em web e app -->
        </v-main>
    </v-app>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from "vuex";
import "typeface-roboto/index.css";
import MxBasic from "@/mixins/Basic";
import MxMobile from "@/mixins/Mobile";
import MxConnection from "@/mixins/Connection";

export default {
    name: "App",
    mixins: [
        MxBasic,
        MxMobile,
        MxConnection,
    ],
    components: {
        "LayoutHeader": () => import(/* webpackChunkName: "layout-header" */ "@/components/LayoutHeader"),
        "FlashMessage": () => import(/* webpackChunkName: "flash-message" */ "@/components/FlashMessage"),
        "SideBarNavigator": () => import(/* webpackChunkName: "sidebar-navigator" */ '@/components/SideBarNavigator'),
        "CompressedSideBarNavigator": () => import(/* webpackChunkName: "compressed-sidebar-navigator" */ '@/components/CompressedSideBarNavigator'),
        "FooterBasic": () => import(/* webpackChunkName: "footer" */ './components/FooterBasic'),
    },
    data() {
        return {
            active_footer: false,
            active_header: false,
            active_sidebar: false,
            active_compressed_sidebar: false,
            // rotas com header/footer; aceita wildcar
            activate_header: [
                '/home',
                '/mostras/listagem',
                '/artistas/listagem',
                '/editar_perfil/*',
                '/obras/*',
                "/eventos/*",
                "/artistas/*",
                "/criar_perfil",
                "/criar_perfil_invite_code/*",
                "/mostras/*",
                "/report",
                "/textos/*",
                "/perfis/*",
                "/rede/*",
                "/editorial/*",
            ],
            activate_footer: [
                "/home",
                "/mostras/listagem",
                "/editar_perfil/*",
                "/obras",
                "/artistas/*",
                "/obras/*",
                "/eventos/*",
                "/criar_perfil",
                "/criar_perfil_invite_code/*",
                "/mostras/*",
                "/textos/*",
                "/perfis/*",
                "/rede/*",
                "/editorial/*",
           ],
            activate_sidebar: [
                "/home",
                "/mostras/listagem",
                "/editar_perfil/*",
                "/artistas/listagem",
                "/obras/listagem",
                "/eventos/listagem",
                "/criar_perfil",
                "/criar_perfil_invite_code/*",
                "/mostras/listagem",
                "/perfis/*",
            ],
            activate_compressed_sidebar: [
                "/obras/*",
                "/artistas/*",
                "/perfis/*",
                "/mostras/*",
                "/textos/*",
                "/eventos/*",
                "/rede/*",
                "/editorial/*",
            ],
            remainingSeconds: 0
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
            tokenExpiresOn: "auth/tokenExpiresOn",
            connected: "global/connected",
            contentSizeStyle: "global/contentSizeStyle",
            isMobile: "global/isMobile",
            windowWidth: "global/windowWidth",
            windowHeight: "global/windowHeight",
            isExibeCarrinho: "global/isExibeCarrinho",
            exibeExpiracao: "auth/exibeExpiracao",
        }),
        footerSideBar() {
            var navOptions = [
                {
                    component: "FooterBasic",
                    active: this.isMobile && this.active_footer,
                },
                {
                    component: "SideBarNavigator",
                    active: !this.isMobile && this.active_sidebar,
                },
                {
                    component: "CompressedSideBarNavigator",
                    active: !this.isMobile && this.active_compressed_sidebar,
                },
            ];
            navOptions = navOptions.filter(e => e.active);
            if (navOptions.length > 0) {
                return navOptions[0].component;
            }
            return false;
        },
        active_sidebar_footer() {
            const activeComponent = [this.isMobile && this.active_footer, this.active_sidebar, this.active_compressed_sidebar].filter(e => e);
            return activeComponent.length > 0;
        },
        styleFooterSidebar() {
            this.setContentViewStyle();
            var style = "position: absolute; bottom: 0px; left: 0px;";
            if (this.isMobile) {
                if (this.active_sidebar) {
                    if (this.isSafariMobile) {
                        style = "overflow: hidden; -webkit-overflow-scrolling: touch; -webkit-transform: translateZ(0); -webkit-transform: translate3d(0,0,0)";
                    } else {
                        if (this.isMobile) {
                            style += "width: 100%";
                        } else {
                            style += "width: 100%; bottom: 56px !important";
                        }
                    }
                } else if (this.active_footer) {
                    if (this.isSafariMobile) {
                        style = "overflow: hidden; -webkit-overflow-scrolling: touch; -webkit-transform: translateZ(0); -webkit-transform: translate3d(0,0,0)";
                    } else {
                        if (this.isMobile) {
                            style += "width: 100%";
                        } else {
                            style += "width: 100%; bottom: 56px !important";
                        }
                    }
                }
            } else {
                // web
                if (this.active_sidebar) {
                    style += "width: 160px; height: 100%;";
                } else if (this.active_compressed_sidebar) {
                    style += "width: 66px; height: 100%;";
                } else if (this.active_footer) {
                    style += "";
                }
            }
        return style;
        },
    },
    watch: {
        "$route.params"() {
            this.setContentViewStyle();
            this.checkWidgets();
        },
        isLoggedIn() {
            if (this.isLoggedIn) {
                this.checkSession();
            }
        },
        connected() {
            if (!this.connected) {
                this.goToUrl('/offline');
            }
        },
    },
    created() {
        this.checkWidgets();
        this.initiateDeviceEvent();
        window.addEventListener('online', this.updateConnectionStatus);
        window.addEventListener('offline', this.updateConnectionStatus);
        window.addEventListener("resize", this.onResize);
        this.setIsMobile(this.checkIsMobile());
        this.setIsExibeCarrinho();
    },
    mounted() {
        this.setWindowSize();
        if (!this.connected) {
            this.goToUrl('/offline');
        }
        if (this.isLoggedIn) {
            this.checkSession();
        }
        if (this.getClientType() === Vue.Constants.CLIENT_MOBILE_BROWSER) {
            const element = document.querySelector('.v-application--wrap');
            if (element) {
                element.classList.add('height-mobile-browser');
            } else {
                element.classList.remove('height-mobile-browser');
            }
        }
    },
    destroyed() { 
        window.removeEventListener('online', this.updateConnectionStatus);
        window.removeEventListener('offline', this.updateConnectionStatus);
        window.removeEventListener("resize", this.onResize);
        this.setExibeExpiracao(false);
    },
    methods: {
        ...mapActions({
            doLogout: "auth/doLogout",
            setContentSizeStyle: "global/setContentSizeStyle",
            setIsMobile: "global/setIsMobile",
            setWindowWidth: "global/setWindowWidth",
            setWindowHeight: "global/setWindowHeight",
            mensagemRefreshSession: "noticias/mensagemRefreshSession",
            setIsExibeCarrinho: "global/setIsExibeCarrinho",
            setExibeExpiracao: "auth/setExibeExpiracao",
        }),
        setWindowSize() {
            const el = document.getElementById("app");
            this.setWindowWidth(el.offsetWidth);
            this.setWindowHeight(el.offsetHeight);
        },
        onResize() {
            this.setWindowSize();
        },
        checkWidgets() {
            // TODO: refatorar num comonente mais inteligente
            // verifica se widgets; header e footer serão exibidos na página a partir de uma lista de exibição
            let currentPage = this.$route.path;
            if (currentPage.indexOf("/") === -1) {
                currentPage = "/" + currentPage;
            }
            // busca se encontra o link igual
            this.checkWidget(currentPage, "header");
            this.checkWidget(currentPage, "footer");
            this.checkWidget(currentPage, "sidebar");
            this.checkWidget(currentPage, "compressed_sidebar");
        },
        setContentViewStyle() {
            var output = "position: absolute;";
            if (this.active_header) {
                let headerHeight =  56;
                output += ` top: ${headerHeight}px !important;`;
            }
            if (this.isMobile && this.active_footer) {
                output += " left: 0; height: auto; width: 100%; bottom: 40px; overflow-y: auto; overflow-x: hidden";
            } else if (!this.isMobile && this.active_sidebar){
                //web
                output += " left: 160px; overflow-y: hidden; overflow-x: hidden; width: "
                output += (this.windowWidth - 160).toString() + "px"
            } else if (!this.isMobile && this.active_compressed_sidebar){
                //web
                output += " left: 66px; overflow-y: hidden; overflow-x: hidden; width: "
                output += (this.windowWidth - 62).toString() + "px"
            } else {
                //caso em que o footer/sidebar ta desativado
                const height = (this.active_footer) ? (this.windowHeight.toString() - 56) : this.windowHeight.toString(); //decide se retira ou nao os 56 px por causa do header
                output += ` height: ${height}px; width: ${(this.windowWidth).toString()}px`
            }
            this.setContentSizeStyle(output);
        },
        checkWidget(currentPage, type) {
            if (typeof this[`active_${type}`] !== "undefined") {
                this[`active_${type}`] =
                    this[`activate_${type}`].indexOf(currentPage) === -1
                        ? false
                        : true;
                // se não encontrar, tenta wildcard
                if (!this[`active_${type}`]) {
                    let wildcard = "*";
                    let hasWildcardsList = this[
                        `activate_${type}`
                    ].filter(item => item.includes(wildcard));
                    hasWildcardsList.forEach(url => {
                        let urlPieces = url.split(wildcard);
                        if (
                            currentPage.indexOf(urlPieces[0]) !== -1 &&
                            currentPage.indexOf(urlPieces[1]) !== -1
                        ) {
                            this[`active_${type}`] = true;
                        }
                    });
                }
            }
        },

        checkSession() {
            const interval = 1000; // miliseconds
            const updateSessionWarn = 30; // seconds

            var timer = setInterval(() => {
                const unixSeconds = parseInt(Date.now()/1000)
                if (!this.tokenExpiresOn) {
                    return;
                }
                this.remainingSeconds = this.tokenExpiresOn - unixSeconds;
                if (this.isLoggedIn && this.remainingSeconds < 1) {
                    this.doLogout();
                    clearInterval(timer);
                    this.setExibeExpiracao(false);
                }
                if (this.isLoggedIn && this.remainingSeconds < updateSessionWarn) {
                    this.setExibeExpiracao(true);
                }
            }, interval);
        },

        refreshSession() {
            this.setExibeExpiracao(false);
            window.location.href='#/refresh_session';
        },

        // TODO: atualizar tokens quando navegação persistir
        refreshTokens() {
            // issue DM-90
        }
    },
};
</script>

<style lang="scss">
@import "@/sass/colors.scss";
@import "@/sass/font.scss";
@import "@/sass/sizing.scss";
@import "@/sass/utils.scss";
@font-face {
    font-family: "Prospectus Pro L";
    src: url("assets/fonts/prospectus/ProspectusLBld.otf");
    font-display: swap;
    color: var(--neutral-900, #161A1D);
}
html {
    overflow-y: hidden;
}

body {
    width: 100%;
    overflow-x: hidden;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

#app {
    width: 100vw;
    font-family: Roboto;
    src: url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: white;
    font-display: swap;
    overflow-y: hidden;
    overflow-x: hidden;
}
a {
    text-decoration: none;
    color: #000 !important;
}

.iosHeight {
    height: 100vh;
}

.iosFooter {
    padding-bottom: 18px;
}

.scrollTouch {
    -webkit-overflow-scrolling: touch;
}

.expiracao {
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    background-color: #60809F;
    color: white;
    text-align: center;
    padding: 2px;
    font-size: 16px;
    z-index: 2000;
    height: 29px;
}

.expiracao-interna1, .expiracao-interna2 {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
}

.expiracao-interna2 {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    margin-left: 3px;
}

.height-mobile-browser {
    min-height: calc(100vh - 45px) !important;
}
</style>
